import { useRef, useState } from 'react';
import { ClickType, useCaptureClick } from '../hooks/useCaptureClick';

enum displayTypes {
  normal = 'normal',
  highlighted = 'highlighted',
}

export const SearchFilters = () => {
  const [display, setDisplay] = useState(displayTypes.normal);
  const wrapperRef = useRef(null);
  const handleClickOutside = () => {
    console.log('user clicked outside');
    // change class from highlighted to normal
    setDisplay(displayTypes.normal);
  };
  useCaptureClick(wrapperRef, ClickType.outside, handleClickOutside);

  const handleClickInside = () => {
    console.log('user clicked inside');
    // change class from normal to highlighted
    setDisplay(displayTypes.highlighted);
  };
  useCaptureClick(wrapperRef, ClickType.inside, handleClickInside);

  return (
    <div
      ref={wrapperRef}
      className={`basis-40 grow shrink-0 ${display === displayTypes.highlighted ? 'bg-red-600' : ''}`}
    >
      <input type="text" className="mx-2 border border-gray-700 rounded" placeholder="Unde?" />
      <select defaultValue={'case'}>
        <option value="case">Case</option>
        <option value="aparatemente">Apartamente</option>
      </select>
      <div>
        <label>Pret</label>
        <input type="text" className="mx-2 border border-gray-700 rounded" placeholder="minim" />
        <input type="text" className="mx-2 border border-gray-700 rounded" placeholder="maxim" />
      </div>
      <button className="px-2 bg-red-700 text-white rounded">search</button>
    </div>
  );
};
