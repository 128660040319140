import { useState } from 'react';
import { useFetchAds } from '../api/useFetchAds';
import { AdList } from '../components/AdList/AdList';
import { AdMap } from '../components/AdMap/AdMap';

export const Home = () => {
  const [showMap, setShowMap] = useState(false);

  const ads = useFetchAds(); // add search params

  const enableMap = () => setShowMap(true);
  const disableMap = () => setShowMap(false);

  return (
    <>
      <AdList ads={ads} enableMapView={enableMap} />
      {<AdMap ads={ads} disableMapView={disableMap} show={showMap} />}
    </>
  );
};
