/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import { GoogleMap, InfoWindow, LoadScript, Marker } from '@react-google-maps/api';
import { Ad } from '../../models/Ad';
import { Config } from '../../config';

interface MapComponentProps {
  width: string;
  height: string;
  lat: number;
  lng: number;
  ads: Ad[];
}

// const divStyle = {
//   background: `white`,
//   border: `1px solid #ccc`,
//   padding: 15,
// };

const MapComponent = ({ width, height, lat, lng, ads }: MapComponentProps) => {
  const [mapInstance, setMapInstance] = useState<any>();

  return (
    <LoadScript googleMapsApiKey={Config.mapsKey}>
      <GoogleMap
        mapContainerStyle={{ width, height }}
        center={{ lat, lng }}
        zoom={10}
        onLoad={(map) => {
          setTimeout(() => setMapInstance(map));
        }}
      >
        {mapInstance &&
          ads.map((ad) => (
            <Marker key={ad.id} position={{ lat: ad.coordinates[0], lng: ad.coordinates[1] }}>
              {/* <InfoWindow>
                <div style={divStyle}>
                  <h1>InfoWindow</h1>
                </div>
              </InfoWindow> */}
            </Marker>
          ))}
      </GoogleMap>
    </LoadScript>
  );
};

export const Map = React.memo(MapComponent);
