import { useEffect } from 'react';

export enum ClickType {
  inside = 'inside',
  outside = 'outside',
}

/**
 * Hook that alerts clicks outside of the passed ref
 */
export const useCaptureClick = (ref: any, clickType: ClickType, callback: () => void) => {
  useEffect(() => {
    const handleClick = (event: any) => {
      if (!ref.current) return false;

      if (
        (!ref.current.contains(event.target) && clickType === ClickType.outside) ||
        (ref.current.contains(event.target) && clickType === ClickType.inside)
      ) {
        // if clicktype === outside
        // console.log(`You clicked ${clickType}!`);
        callback();
      }
    };
    // Bind the event listener
    document.addEventListener('mousedown', handleClick);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClick);
    };
  }, [ref, clickType, callback]);
};
