interface PriceFormatProps {
  price: number;
  currency?: string;
  locale?: string;
}

export const PriceFormat = ({ price, currency = 'EUR', locale = 'de-DE' }: PriceFormatProps) => (
  <>
    {new Intl.NumberFormat(locale, {
      style: 'currency',
      currency,
      maximumFractionDigits: 0,
    })
      .format(price)
      .replace('.', ' ')}
  </>
);
