interface ToggleButtonOverlayProps {
  text: string;
  action: () => void;
}

export const ToggleButtonOverlay = ({ text, action }: ToggleButtonOverlayProps) => {
  return (
    <div className="fixed bottom-16 z-10 flex justify-center w-screen pointer-events-none">
      <span onClick={action} className=" rounded-lg bg-red-800 px-4 py-1 cursor-pointer text-white pointer-events-auto">
        {text}
      </span>
    </div>
  );
};
