import { Link } from 'react-router-dom';
import logo from '../logo.svg';
import { SearchFilters } from './SearchFilters';

export const Header = () => (
  <>
    <header className="flex space-around items-center sticky top-0 bg-white border-b border-slate-500">
      <div className="flex basis-60 grow shrink-0">
        <Link to="/">
          <img src={logo} alt="ImoTracker" className="w-16" />
        </Link>
        <Link to="/">
          <h1 className="text-3xl font-bold">ImoTracker</h1>
        </Link>
      </div>
      <SearchFilters />
    </header>
  </>
);
