import { useQuery } from 'react-query';
import { Ad } from '../models/Ad';

const colors = ['8ecae6'].concat(
  ['219ebc', '023047', 'ffb703', 'fb8500'],
  ['001219', '005f73', '0a9396', '94d2bd', 'e9d8a6', 'ee9b00', 'ca6702', 'bb3e03', 'ae2012', '9b2226'],
);

const locations = ['Breaza, PH', 'Campina, PH', 'Zarnesti, BV', 'Rucar, DB'];

const mapCoordinates = {
  topLeft: [45.74, 24.88],
  bottomLeft: [45.08, 24.88],
  bottomRight: [45.08, 25.92],
  topRight: [45.74, 25.92],
};

const random = (min: number, max: number) => min + ~~(Math.random() * (max - min));

const pickColor = () => colors[random(0, colors.length)];

const generateAds = (limit: number) =>
  [...Array(limit).keys()].map(
    (n): Ad => ({
      id: `unique${n + 1}`,
      title: `Ad Sample no${n + 1}`,
      get image() {
        const color = pickColor();
        return `https://via.placeholder.com/400x400/${color}/${color}`;
      },
      coordinates: [
        random(mapCoordinates.topLeft[0] * 10000, mapCoordinates.bottomLeft[0] * 10000) / 10000,
        random(mapCoordinates.topLeft[1] * 10000, mapCoordinates.topRight[1] * 10000) / 10000,
      ],
      location: locations[random(0, locations.length - 1)],
      price: random(75000, 125000),
    }),
  );

const adsFixture: Ad[] = generateAds(24);

export const useFetchAds = () => useQuery<Ad[], Error>('ads', () => Promise.resolve(adsFixture));
