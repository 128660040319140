import React from 'react';
import { UseQueryResult } from 'react-query';
import { Ad } from '../../models/Ad';
import { ToggleButtonOverlay } from '../ToggleButtonOverlay';
import { AdListItem } from './AdListItem';

interface AdListProps {
  enableMapView: () => void;
  ads: UseQueryResult<Ad[], Error>;
}

const AdListComponent = ({ enableMapView, ads }: AdListProps) => {
  return (
    <>
      {ads.data && (
        <section className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 xl:mx-16 gap-4">
          {ads.data.map((ad) => (
            <AdListItem ad={ad} key={ad.id} />
          ))}
        </section>
      )}
      <ToggleButtonOverlay text="show map" action={enableMapView} />
    </>
  );
};

export const AdList = React.memo(AdListComponent);
