import React from 'react';
import { UseQueryResult } from 'react-query';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import { Ad } from '../../models/Ad';
import { ToggleButtonOverlay } from '../ToggleButtonOverlay';
import { Map } from './Map';

interface AdMapProps {
  disableMapView: () => void;
  ads: UseQueryResult<Ad[], Error>;
  show: boolean;
}

const AdMapComponent = ({ disableMapView, ads, show }: AdMapProps) => {
  const { height, width } = useWindowDimensions();

  const style = {
    width: `${width}px`,
    height: `${height - 45}px`,
  };

  const center = {
    lat: 45.283008,
    lng: 25.295465,
  };

  return (
    <div className={`fixed bottom-0 z-10 ${show ? '' : 'hidden'}`}>
      {ads.data && <Map lat={center.lat} lng={center.lng} width={style.width} height={style.height} ads={ads.data} />}
      <ToggleButtonOverlay text="show list" action={disableMapView} />
    </div>
  );
};

export const AdMap = React.memo(AdMapComponent);
