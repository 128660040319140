import { Route, Routes } from "react-router-dom";
import { Home } from "./screens/Home";
import { AdDetail } from "./screens/AdDetail";

export const Main = () => (
  <Routes>
    <Route path="/" element={<Home />} />
    <Route path="/detail/:ad" element={<AdDetail />} />
  </Routes>
);
