import { Ad } from '../../models/Ad';
import { PriceFormat } from '../utilities/PriceFormat';

interface AdListItemProps {
  ad: Ad;
}

export const AdListItem = ({ ad }: AdListItemProps) => {
  return (
    <div className="">
      {ad.image && <img src={ad.image} alt={ad.title} className="rounded-lg" />}
      <div className="details">
        <h3>{ad.title}</h3>
        {ad.location && (
          <p>
            {ad.location} {ad.coordinates && `[${ad.coordinates.join(', ')}]`}
          </p>
        )}
        {ad.price && (
          <p>
            <PriceFormat price={ad.price} />
          </p>
        )}
      </div>
    </div>
  );
};
